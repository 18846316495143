import { render, staticRenderFns } from "./signResign.vue?vue&type=template&id=18484b04&scoped=true&"
import script from "./signResign.vue?vue&type=script&lang=js&"
export * from "./signResign.vue?vue&type=script&lang=js&"
import style0 from "./signResign.vue?vue&type=style&index=0&id=18484b04&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18484b04",
  null
  
)

export default component.exports