<template>
  <div class="resign">
    <div class="bg"></div>
    <div class="sign-bg">
      <div class="sign-main">
        <img
          src="@/assets/sign-a.png"
          class="sign-a pointer"
          @click="toUrl('/index')"
        />
        <div class="sign-form">
          <div class="forms">
            <div class="forms-tit">注册账户</div>
            <div class="steps flex between">
              <div
                class="step-a"
                :class="{ 'steps-a': step == 0 || step == 1 || step == 2 }"
              >
                <img src="@/assets/sign-c.png" />
                <div>1. 注册验证</div>
              </div>
              <div
                class="step-a"
                :class="{ 'steps-a': step == 1 || step == 2 }"
              >
                <img src="@/assets/sign-c.png" v-if="step == 1 || step == 2" />
                <img src="@/assets/sign-d.png" v-else />
                <div>2. 实名验证</div>
              </div>
              <div class="step-a" :class="{ 'steps-a': step == 2 }">
                <img src="@/assets/sign-c.png" v-if="step == 2" />
                <img src="@/assets/sign-d.png" v-else />
                <div>3. 完成注册</div>
              </div>
            </div>
            <div v-if="step == 0">
              <div class="input-div">
                <input v-model="mobile" placeholder="请输入注册手机号" />
              </div>
              <div class="input-div2 flex between">
                <div class="input-div3">
                  <input v-model="verification_code" placeholder="验证码" />
                </div>
                <div class="code-div pointer" @click="getCode" v-if="isShow">
                  获取验证码
                </div>
                <div class="code-div code-div2" v-else>
                  <span>{{ count }}</span> 秒后重新获取
                </div>
              </div>
              <div class="input-div">
                <input v-model="password" placeholder="设置密码" />
              </div>
            </div>
            <div v-if="step == 1">
              <div class="input-div">
                <input v-model="truename" placeholder="请输入真实姓名" />
              </div>
              <div class="input-div">
                <input v-model="id_number" placeholder="请输入本人身份证号" />
              </div>
            </div>
            <div v-if="step == 2" class="success">
              <i class="el-icon-success"></i>
              <div>
                注册成功！<span class="pointer" @click="toUrl('/signLogin')"
                  >登录</span
                >
              </div>
            </div>
            <div class="tips" v-if="step != 2">
              已有账号，<span class="pointer" @click="toUrl('/signLogin')"
                >去登录</span
              >
            </div>
            <div class="login-btn pointer" @click="toStep(1)" v-if="step == 0">
              下一步
            </div>
            <div class="flex between login-btns" v-if="step == 1">
              <div class="login-btn pointer" @click="toStep(0)">上一步</div>
              <div class="login-btn pointer" @click="toResign">注册</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userRegister, sendRegisterCode } from "@/api/apiData";
export default {
  data() {
    return {
      mobile: "", //手机号
      verification_code: "", //短信验证码
      password: "", //密码
      truename: "", //真实姓名
      id_number: "", //证件号码
      status: 1,
      step: 0,
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      count: 0, //倒计时 计数器
      msg: "", //如果msg为空是蓝色，点击之后变灰色
    };
  },
  mounted() {
    this.$bus.emit("navS", 0);
  },
  methods: {
    toUrl(u) {
      this.$router.push(u);
    },
    toStep(n) {
      this.step = n;
    },
    // 获取验证码
    getCode() {
      if (this.mobile == "") {
        this.$message.error("请输入手机号");
      } else {
        sendRegisterCode({ phone: this.mobile }).then((res) => {
          if (res.code == 1) {
            this.isShow = false;
            this.count = 60; //赋值3秒
            var times = setInterval(() => {
              this.count--; //递减
              if (this.count <= 0) {
                // <=0 变成获取按钮
                this.isShow = true;
                clearInterval(times);
              }
            }, 1000); //1000毫秒后执行
            this.$message({
              type: "success",
              message: res.msg,
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 注册
    toResign() {
      let that = this;
      let obj = {
        mobile: that.mobile,
        verification_code: that.verification_code,
        password: that.password,
        truename: that.truename,
        id_number: that.id_number,
      };
      if (obj.mobile == "") {
        this.$message.error("请输入注册手机号");
      } else if (obj.verification_code == "") {
        this.$message.error("请输入验证码");
      } else if (obj.password == "") {
        this.$message.error("请输入密码");
      } else if (obj.truename == "") {
        this.$message.error("请输入真实姓名");
      } else if (obj.id_number == "") {
        this.$message.error("请输入本人身份证号");
      } else {
        this.$confirm("确定提交信息注册吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            userRegister(obj).then((res) => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: "注册成功!",
                });
                that.step = 2;
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #539fea;
  z-index: -1;
}
.sign-bg {
  width: 100%;
  .sign-main {
    width: 1073px;
    margin: 80px auto;
    .steps {
      .step-a {
        width: 32%;
        position: relative;
        font-size: 15px;
        color: #c4c4c4;
        line-height: 35px;
        height: 35px;
        text-align: center;
        div {
          position: absolute;
          width: 100%;
          height: 100%;
          line-height: 35px;
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
      .steps-a {
        color: white;
      }
    }
    .sign-a {
      width: 50%;
      margin-bottom: 30px;
    }
    .sign-form {
      width: 100%;
      background: white;
      padding: 50px 0;
      .forms {
        width: 40%;
        margin: 0 auto;
        .forms-tit {
          font-size: 20px;
          text-align: center;
          margin-bottom: 30px;
        }
        .input-div {
          padding: 15px;
          border-bottom: solid 1px #d1d1d1;
          margin-top: 30px;
        }
        .input-div2 {
          padding: 10px 0;
          margin-top: 30px;
          .input-div3 {
            width: 70%;
            border-bottom: solid 1px #d1d1d1;
            padding-bottom: 15px;
            padding-left: 15px;
          }
          .code-div {
            background: #0089e1;
            width: 30%;
            line-height: 37px;
            font-size: 14px;
            text-align: center;
            color: white;
          }
          .code-div2 {
            background: #3da4e4;
          }
        }
        input {
          border: none;
          font-size: 16px;
        }
        .tips {
          text-align: right;
          font-size: 14px;
          color: #c4c4c4;
          margin: 20px 0 30px;
          span {
            color: #0089e1;
          }
        }

        .login-btn {
          width: 100%;
          background: #0089e1;
          color: white;
          font-size: 20px;
          text-align: center;
          line-height: 49px;
          border-radius: 4px;
          margin: 40px 0 20px;
        }
        .login-btns {
          .login-btn {
            width: 48%;
          }
          .login-btn:nth-child(1) {
            background: #7cc3f0;
          }
        }
      }
    }
  }
}
.success {
  color: #0089e1;
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
  i {
    font-size: 130px;
    margin-bottom: 20px;
  }
  span {
    color: #084fd4;
    border-bottom: solid 1px;
  }
}
</style>